* {
    padding: 0; 
    margin: 0;
}

.nav .nav-item {
    border-bottom: 3px solid transparent;
}

.nav .nav-item:hover {
    border-bottom: 3px solid #357edd;
}