.bounding-box {
    position: absolute;
    cursor: pointer;
    box-shadow: 0 0 0 10px #357edd inset;
}

.container 
.bounding-box-container {
    width: 100%;
    height: 100%;
    flex: 1 0 20% !important;
}

img {
    width: auto;
    height: auto;
    object-fit: contain;
}

.imgviewer {
    margin: 0 auto;
    text-align: center;
}